import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import axios from 'axios';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { Link, useNavigate } from 'react-router-dom';
import './App.css';
import { FaFacebook, FaMedium, FaTwitter } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid'; // Import a UUID library
import { useWebSocket } from './WebSocketContext';
import { format, toZonedTime, formatInTimeZone } from 'date-fns-tz';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom'; 

const mapContainerStyle = {
  height: '100%',
  width: '100%',
};

const DEFAULT_CENTER = { lat: 37.7749, lng: -122.4194 };
const DEFAULT_ZOOM = 5;
const DEFAULT_PROVIDER = 'mapbox';
const DEFAULT_PLAN = 'Basic';
const apiUrl = process.env.REACT_APP_API_URL;

const colorOptions = ['Blue', 'Red', 'Green', 'Purple', 'Pink', 'Yellow', 'Orange', 'Light Blue'];

const geocodifyApiKey = process.env.REACT_APP_GEOCODIFY_API_KEY; 
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
//const mapboxApiKey = 'pk.eyJ1IjoiaWRlYWx1c2VyIiwiYSI6ImNtMG15bzYwMTA3eG0ybW9wdnN4bTg2eXMifQ.Kb8gxDbHJvYA18e4a4nmw';
const mapboxApiKey = process.env.REACT_APP_MAPBOX_API_KEY;

const googleMapsLibraries = ['places'];
const googleMapsOptions = {
  googleMapsApiKey,
  libraries: googleMapsLibraries,
  id: 'script-loader',
  version: 'weekly',
  language: 'en',
  region: 'US',
};

// Define default values for colors if the categorization is empty or API call fails
const DEFAULT_CATEGORIZATION = {
  red: 'Shopping',
  orange: 'Entertainment',
  green: 'To do',
  blue: 'Travel',
  yellow: 'Food',
  purple: 'Health',
  ltblue: 'Reminder'
};


function formatTimestamp(date) {
  const pad = (num) => (num < 10 ? '0' + num : num);
  const padMilliseconds = (num) => {
    if (num < 10) return '00' + num;
    if (num < 100) return '0' + num;
    return num.toString();
  };

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const milliseconds = padMilliseconds(date.getMilliseconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

const MainApp = () => {
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const notesPerPage = 10;
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null);
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [isNightMode, setIsNightMode] = useState(false);
  const [categorization, setCategorization] = useState(DEFAULT_CATEGORIZATION);
  const [selectedTab, setSelectedTab] = useState(null);
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editedNote, setEditedNote] = useState('');
  const [originalNote, setOriginalNote] = useState('');
  const [editedLink, setEditedLink] = useState('');
  const [newBox, setNewBox] = useState({ key: '', value: '', visible: false });
  const [editedEmailSubject, setEditedEmailSubject] = useState('');
  const [colorFilter, setColorFilter] = useState('');
  const navigate = useNavigate();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const { isLoaded, loadError } = useJsApiLoader(googleMapsOptions);
  const autocompleteRef = useRef(null);
  const [clickPosition, setClickPosition] = useState(null);
  const { socket, clientId, connectionId, setConnectionId } = useWebSocket(); 
  const clientIdRef = useRef(clientId);
  const [userTimezone, setUserTimezone] = useState('UTC'); // Default to UTC
  const [theme, setTheme] = useState('Light'); // Initialize theme state
  const [isUpdatingNote, setIsUpdatingNote] = useState(false); // Add this state
  const [provider, setProvider] = useState(DEFAULT_PROVIDER); // Initialize provider state
  const [plan, setPlan] = useState(DEFAULT_PLAN); // Initialize plan state
  const providerRef = useRef(provider); // Create ref for provider
  const location = useLocation();
  const [filters, setFilters] = useState({
    noteCreationDate: '',
    fromEmail: '',
    noteActive: '',
    lastEdited: '',
    recTags: '',
    activities: '',
    bodyText: '',
    emailSubject: '',
    name: '',
    description: '',
    categoryColor: '',
    categoryDescription: '',
    favorite: '',
    recTimes: ''
  });

    // Pagination component
    const Pagination = () => (
      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredNotes.length / notesPerPage) }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>
    );

  const fetchGeolocation = async () => {
    try {
      const response = await axios.get('https://ipinfo.io/json?token=4573cb49cafe14');
      const location = response.data.loc.split(',');
      setCenter({ lat: parseFloat(location[0]), lng: parseFloat(location[1]) });
      setZoom(5);
    } catch (error) {
      console.error('Error fetching geolocation, falling back to default:', error);
      setCenter(DEFAULT_CENTER);
      setZoom(DEFAULT_ZOOM);
    }
  };

  const normalizeKeys = (obj) => {
    // Convert all keys to lowercase
    const normalizedObj = {};
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        normalizedObj[key.toLowerCase()] = obj[key];
      }
    }
    return normalizedObj;
  };

  const getUserFromToken = (token) => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.sub;
    } catch (error) {
      console.error('Error parsing JWT token:', error);
      return null;
    }
  };
    // Fetch user information, including plan, theme, and categorization
    const fetchUserInfo = async () => {

      try {
        const session = await fetchAuthSession(); // Modify as needed based on your authentication setup
        const jwtToken = session?.idToken?.jwtToken;
        const token = (await fetchAuthSession()).tokens?.idToken?.toString();
  
        // Extract the email from the JWT token
        const user = getUserFromToken(token);
  
        if (!user) {
          alert('Failed to retrieve user from JWT token.');
          return;
        }
  
        const headers = {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${token}`
        };
  
        // Define the API URL with the userID path parameter (adjust as necessary)
        const url = `${apiUrl}/users/${encodeURIComponent(user)}`;
        // Make the GET request to the API with JWT in the header
        const response = await axios.get(url, { headers });
        //console.log('response:', response);
        // Assuming the response is an array with one object
        const data = response.data[0];
        //const userData = response.data[0];
        //const data = response.data;
            // Normalize categorization keys to lowercase
        const categorizationData = normalizeKeys(data.categorization || {});
        //console.log('Normalized Categorization Data:', categorizationData); 
  
        // Extract and update user information
        //const categorizationData = data.categorization || {};
    // Set categorization state using normalized keys
        setCategorization({
          red: categorizationData.red || DEFAULT_CATEGORIZATION.red,
          orange: categorizationData.orange || DEFAULT_CATEGORIZATION.orange,
          green: categorizationData.green || DEFAULT_CATEGORIZATION.green,
          blue: categorizationData.blue || DEFAULT_CATEGORIZATION.blue,
          yellow: categorizationData.yellow || DEFAULT_CATEGORIZATION.yellow,
          purple: categorizationData.purple || DEFAULT_CATEGORIZATION.purple,
          ltblue: categorizationData.lightblue || DEFAULT_CATEGORIZATION.ltblue,
        });
  
        //console.log('Categorization Data:', categorizationData);
        //Object.entries(categorizationData).forEach(([key, value]) => {
        //  console.log(`Key: ${key}, Value: ${value}`);
        //});

        // Update theme and isNightMode
        setTheme(data.theme || 'Light');
        setIsNightMode((data.theme || 'Light').toLowerCase() === 'dark');

        // Set the user's timezone
        setUserTimezone(data.timezone || 'UTC');
  
        // Update plan if available
        if (data.plan) {
          setPlan(data.plan);
        }
  
        // Update connectionId if available
        //if (data.clientId) {
        //  setConnectionId(data.clientId);
        //}
      } catch (error) {
        console.error('Error fetching user information:', error);
        // Use default categorization if API call fails
        setCategorization(DEFAULT_CATEGORIZATION);
      }
    };

  const fetchNotes = async () => {
    try {
      const token = (await fetchAuthSession()).tokens?.idToken?.toString();
      const headers = {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${token}`
      };
      //console.log('WebSocket connection established  when retrievinv notes with ID: ' + clientId.current);
      //console.log('WebSocket connection established  when retrievinv notes with ID: ' + clientIdRef.current);
      //const response = await axios.get('https://rccd7493ld.execute-api.us-west-2.amazonaws.com/dev/notes', { headers });
      const response = await axios.get(`${apiUrl}/notes`, { headers });
      const data = response.data;

      if (data.Items) {
        setNotes(data.Items);
        setFilteredNotes(data.Items);
        if (data.Items.length > 0) {
          geocodeAddresses(data.Items);
        }
      }
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  // Function to call Mapbox Geocoding API
  const fetchGeolocationFromProvider = async (address) => {
    console.log(`fetchGeolocationFromProvider`);
    try {
      const currentProvider = providerRef.current; // Use providerRef to access the current provider
      let response;
      if (currentProvider === 'mapbox') {
        console.log(`Sending request to mapbox:`);
        response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json`, {
        //response = await axios.get(`https://api.mapbox.com/search/geocode/v6/forward`, {
          //params: { q: address, access_token: mapboxApiKey, language: 'en', },
          params: {
            access_token: mapboxApiKey,
            limit: 1,
            language: 'en', // Enforce English language for address results
            types: 'address,place', // Specify types to filter results for more accurate addresses
          },
        });
        if (response.data.features && response.data.features.length > 0) {
          console.log("mapbox response: " + response.status);
          console.log("mapbox response: " + response.data.features[0].geometry.coordinates);
          const [lng, lat] = response.data.features[0].geometry.coordinates;
          return { lat, lng };
        }
      } else if (currentProvider === 'google') {
        console.log(`Sending request to google:`);
        response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
          params: { address, key: googleMapsApiKey, language: 'en', },
        });
        if (response.data.results && response.data.results.length > 0) {
          console.log("google response: " + response.status);
          console.log("google response: " + response.data.results[0].geometry.location);
          const { lat, lng } = response.data.results[0].geometry.location;
          return { lat, lng };
        }
      } else if (currentProvider === 'geocode') {
        console.log(`Sending request to geocode:`);
        response = await axios.get(`https://api.geocodify.com/v2/geocode`, {
          params: { api_key: geocodifyApiKey, q: address },
        });
        if (response.data.response.features.length > 0) {
          const location = response.data.response.features[0].geometry.coordinates;
          return { lat: location[1], lng: location[0] };
        }
      }
      throw new Error('No location found for the given address.');
    } catch (error) {
      console.error(`Error fetching location from ${providerRef.current}:`, error);
      return null;
    }
  };

  const fetchAddressFromCoordinates = async (lat, lng) => {
    console.log(`fetchAddressFromCoordinates`);
    try {
      const currentProvider = providerRef.current; // Use providerRef to access the current provider
      let response;
      if (currentProvider === 'mapbox') {
        console.log(`Sending request to mapbox lat long:`);
        response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json`, {
        //response = await axios.get(`https://api.mapbox.com/search/geocode/v6/reverse`, {
          //params: { longitude: `${lng}`, latitude: `${lat}`, access_token: mapboxApiKey, },
          params: {
            access_token: mapboxApiKey,
            limit: 1,
            language: 'en', // Enforce English language for address results
          },
        });
        if (response.data.features && response.data.features.length > 0) {
          return response.data.features[0].place_name || 'Unknown address';
        }
      } else if (currentProvider === 'google') {
        console.log(`Sending request to google lat long:`);
        response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
          params: { latlng: `${lat},${lng}`, key: googleMapsApiKey },
        });
        if (response.data.results && response.data.results.length > 0) {
          return response.data.results[0].formatted_address || 'Unknown address';
        }
      } else if (currentProvider === 'geocode') {
        console.log(`Sending request to geocode lat long:`);
        response = await axios.get(`https://api.geocodify.com/v2/reverse`, {
          params: { api_key: geocodifyApiKey, q: `${lat},${lng}` },
        });
        if (response.data.response.features.length > 0) {
          return response.data.response.features[0].properties.label || 'Unknown address';
        }
      }
      throw new Error('No address found for the given coordinates.');
    } catch (error) {
      console.error(`Error fetching address from ${providerRef.current}:`, error);
      return 'Unknown address';
    }
  };

  const centerMapOnAddress = async (address, geolat_long = null) => {
    try {
      // If geolat_long is provided, use it directly
      if (geolat_long) {
        const latLng = geolat_long.split(',').map(Number);
        setCenter({ lat: latLng[0], lng: latLng[1] });
        setZoom(5); // Adjust zoom level as needed
        return;
      }
  
      // If no geolat_long is provided, perform a geocode lookup
      if (!address || address.trim().toUpperCase() === "TBD") {
        console.warn(`Skipping geocoding for address: "${address}"`);
        return;
      }
  
      // Fetch location from Mapbox
      const location = await fetchGeolocationFromProvider(address);
      if (location) {
        setCenter(location);
        setZoom(5); // Adjust zoom level as needed
      } else {
        console.error(`Error geocoding address "${address}"`);
      }
    } catch (error) {
      console.error('Error geocoding address:', error.message);
    }
  };
  
  

  const updateNoteWithGeolatLong = async (noteID, latLng, originalNote) => {
    if (isUpdatingNote) return; // Prevent multiple updates
  
    setIsUpdatingNote(true); // Mark the update as in progress
  
    try {
      const token = (await fetchAuthSession()).tokens?.idToken?.toString();
      const headers = {
        'Content-type': 'application/json; charset=UTF-8',
        'Accept': 'application/json, application/xml',
        'Authorization': `Bearer ${token}`,
      };
  
      const updatedNote = { ...originalNote };
  
      // Add the geolat_long attribute
      if (!updatedNote.aiPromptResults) {
        updatedNote.aiPromptResults = {};
      }
      updatedNote.aiPromptResults.geolat_long = { value: `${latLng[0]},${latLng[1]}` };
  
      // Preserve current lastEdited time and include other note details
      const noteDetails = {
        dateCreated: formatTimestamp(new Date(updatedNote.noteCreationDate)),
        lastUpdated: formatTimestamp(new Date(updatedNote.lastEdited)), // Keep the current lastEdited time
        fromEmail: updatedNote.fromEmail || '',
        emailSubject: updatedNote.manualPromptResults?.emailSubject || '',
        activeState: updatedNote.noteActive ? 'True' : 'False',
      };
  
      const tags = updatedNote.manualPromptResults?.manualTags || {};
  
      const aiRecommendations = {
        address: updatedNote.aiPromptResults?.address || { value: '', probability: 0 },
        recTags: Array.isArray(updatedNote.aiPromptResults?.recTags) ? updatedNote.aiPromptResults.recTags : [],
        activities: Array.isArray(updatedNote.aiPromptResults?.activities) ? updatedNote.aiPromptResults.activities : [],
        lat_long: updatedNote.aiPromptResults?.lat_long || { value: '', probability: 0 },
        geolat_long: updatedNote.aiPromptResults?.geolat_long || { value: `${latLng[0]},${latLng[1]}`  },
        name: updatedNote.aiPromptResults?.name || { value: '', probability: 0 },
        description: updatedNote.aiPromptResults?.description || { value: '', probability: 0 },
        recTimes: Array.isArray(updatedNote.aiPromptResults?.recTimes) ? updatedNote.aiPromptResults.recTimes : [],
        category: Array.isArray(updatedNote.aiPromptResults?.category) ? updatedNote.aiPromptResults.category : [],
        nearby: Array.isArray(updatedNote.aiPromptResults?.nearby) ? updatedNote.aiPromptResults.nearby : [],
      };
  

      const urlsArray = updatedNote.manualPromptResults?.url || [];
      const urlsPresent = urlsArray.length > 0;

      const combinedBodyText = JSON.stringify({
        bodyText: updatedNote.manualPromptResults?.bodyText || '',
        url: urlsArray,
        urlsPresent: urlsPresent,
        noteDetails,
        tags,
        aiRecommendations,
        //clientId: connectionId,
        lastEdited: formatTimestamp(new Date(originalNote.lastEdited)),
      }, null, 2);
  
      await axios.put(
        //`https://rccd7493ld.execute-api.us-west-2.amazonaws.com/dev/notes/${noteID}`,
        `${apiUrl}/notes/${noteID}`,
        { bodyText: combinedBodyText },
        { headers }
      );
  
      console.log(`Note ${noteID} updated successfully with geolat_long:`, latLng);
  
    } catch (error) {
      console.error(`Error updating note ${noteID} with geolat_long: ${error.message}`);
      if (error.response) {
        console.error(`HTTP Status Code: ${error.response.status}`);
        console.error('Response data:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up the request:', error.message);
      }
    } finally {
      setIsUpdatingNote(false); // Mark the update as complete
    }
  };
  


  const geocodeAddresses = async (items) => {
    try {
      const geocodedMarkers = await Promise.all(
        items.map(async (item) => {
          const address = item.manualPromptResults?.manualTags?.Address || item.aiPromptResults?.address?.value;
  
          // Check if geolat_long exists
          let latLng;
          if (item.aiPromptResults?.geolat_long?.value) {
            latLng = item.aiPromptResults.geolat_long.value.split(',').map(Number);
          } else if (address) {
            try {
              const location = await fetchGeolocationFromProvider(address);
              if (location) {
                latLng = [location.lat, location.lng];
                await debouncedUpdateNoteWithGeolatLong(item.noteID, latLng, item); // Update note with geolat_long
              }
            } catch (error) {
              console.error(`Error geocoding address "${address}": ${error.message}`);
              return null;
            }
          }
  
          if (latLng) {
            const color = item.manualPromptResults?.manualTags?.Categorization || item.aiPromptResults?.category?.[0]?.color?.value;
            const marker = {
              lat: latLng[0], // Ensure the correct order: latLng[0] is latitude, latLng[1] is longitude
              lng: latLng[1],
              noteID: item.noteID,
              title: item.manualPromptResults?.emailSubject,
              body: item.manualPromptResults?.url,
              category: color,
            };
            return marker;
          } else {
            return null;
          }
        })
      );
  
      const validMarkers = geocodedMarkers.filter((marker) => marker !== null);
      setMarkers(validMarkers);
  
      if (validMarkers.length > 0) {
        setCenter(validMarkers[0]);
      }
    } catch (error) {
      console.error('Error geocoding addresses:', error);
    }
  };
  


const createAdvancedMarkers = async (map, markers) => {
  const google = window.google;
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
  console.log(`Map Change: createAdvancedMarkers`);
  
  markers.forEach(marker => {
    const icon = document.createElement("div");
    icon.className = marker.noteID;
    icon.textContent = marker.body;
    icon.innerHTML = '<i className="fa fa-pizza-slice fa-lg"></i>';

    let categoryColor = marker.category;
    const note = notes.find(n => n.noteID === marker.noteID);
    if (note && note.manualPromptResults && note.manualPromptResults.manualTags && note.manualPromptResults.manualTags.Categorization) {
      categoryColor = note.manualPromptResults.manualTags.Categorization;
    }
    if (note && note.manualPromptResults && note.manualPromptResults.manualTags && note.manualPromptResults.manualTags.Color) {
      categoryColor = note.manualPromptResults.manualTags.Color;
    }

    const beachFlagImg = document.createElement("img");
    beachFlagImg.src = getMarkerIconUrl(categoryColor);

    const advancedMarker = new AdvancedMarkerElement({
      map: map,
      position: { lat: marker.lat, lng: marker.lng }, // Ensure lat and lng are correctly assigned
      title: marker.title,
      content: beachFlagImg,
    });
    //console.log(`Marker created with following latlong:`, advancedMarker.position);
    advancedMarker.addListener('click', () => {
      filterNotesByMarker(marker.noteID);
    });

    if (!map.markers) {
      map.markers = [];
    }
    map.markers.push(advancedMarker);

  });
};


  const getMarkerIconUrl = (category) => {
    const upperCaseString = category?.toUpperCase();
    switch (upperCaseString) {
      case 'BLUE':
        return 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
      case 'RED':
        return 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
      case 'GREEN':
        return 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
      case 'PURPLE':
        return 'https://maps.google.com/mapfiles/ms/icons/purple-dot.png';
      case 'PINK':
        return 'https://maps.google.com/mapfiles/ms/icons/pink-dot.png';
      case 'YELLOW':
        return 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
      case 'ORANGE':
        return 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png';
      case 'LIGHT BLUE':
        return 'https://maps.google.com/mapfiles/ms/icons/ltblue-dot.png';
      default:
        return 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
    }
  };

  const filterNotesByMarker = (noteID) => {
    const filtered = notes.filter(note => note.noteID === noteID);
    setFilteredNotes(filtered);
    setCurrentPage(1);
  };


  const handleNoteSelect = (note) => {
    const marker = markers.find((marker) => marker.noteID === note.noteID);

    if (marker && mapRef.current) {
      //console.log("Moving map too: " + marker.lat + "," + marker.lng);
      mapRef.current.panTo({ lat: marker.lat, lng: marker.lng });
      mapRef.current.setZoom(12);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === 'categoryColor') {
      setColorFilter(value);
      setFilters({ ...filters, [name]: value });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    if (filter === 'favorite') {
      setFilters({ ...filters, favorite: 'favorite' });
    } else {
      setFiltersVisible(true);
    }
  };

  const handleAddNewBox = (noteId, tabName) => {
    setEditingNoteId(noteId);
    setNewBox({ ...newBox, visible: true });
    setSelectedTab(`${noteId}-My Tags`);
  };

  const handleSaveNewBox = (noteId, tabName) => {
    setNotes(prevNotes => {
      return prevNotes.map(note => {
        if (note.noteID === noteId) {
          const updatedNote = { ...note };
          const capitalizedKey = newBox.key.charAt(0).toUpperCase() + newBox.key.slice(1).toLowerCase();

          if (tabName === 'My Tags') {
            if (!updatedNote.manualPromptResults) {
              updatedNote.manualPromptResults = {};
            }
            if (!updatedNote.manualPromptResults.manualTags) {
              updatedNote.manualPromptResults.manualTags = {};
            }
            if (capitalizedKey === 'Categorization') {
              const validColors = colorOptions.map(color => color.toLowerCase());
              if (!validColors.includes(newBox.value.toLowerCase())) {
                alert('Invalid color option');
                return note;
              }
            }
            updatedNote.manualPromptResults.manualTags[capitalizedKey] = newBox.value;
          } else if (tabName === 'Recommendations') {
            if (!updatedNote.aiPromptResults) {
              updatedNote.aiPromptResults = {};
            }
            updatedNote.aiPromptResults[capitalizedKey] = { value: newBox.value };
          }
          return updatedNote;
        }
        return note;
      });
    });
    setNewBox({ key: '', value: '', visible: false });
  };
  

  const applyFilters = () => {
    if (!notes) return;

    const filtered = notes.filter((note) => {
      const { manualPromptResults, aiPromptResults, noteCreationDate, fromEmail, noteActive, lastEdited, recTimes } = note;

      const bodyText = typeof manualPromptResults?.bodyText === 'string' ? manualPromptResults.bodyText.toLowerCase() : '';
      const emailSubject = typeof manualPromptResults?.emailSubject === 'string' ? manualPromptResults.emailSubject.toLowerCase() : '';
      const name = typeof aiPromptResults?.name?.value === 'string' ? aiPromptResults.name.value.toLowerCase() : '';
      const description = typeof aiPromptResults?.description?.value === 'string' ? aiPromptResults.description.value.toLowerCase() : '';
      const categoryColor = (typeof manualPromptResults?.manualTags?.Categorization === 'string'
        ? manualPromptResults.manualTags.Categorization.toLowerCase()
        : typeof aiPromptResults?.category?.[0]?.color?.value === 'string'
          ? aiPromptResults.category[0].color.value.toLowerCase()
          : '');
      const categoryDescription = typeof aiPromptResults?.category?.[0]?.description?.value === 'string' ? aiPromptResults.category[0].description.value.toLowerCase() : '';
      const recTags = typeof aiPromptResults?.recTags?.value === 'string' ? aiPromptResults.recTags.value.toLowerCase() : '';
      const activities = typeof aiPromptResults?.activities?.value === 'string' ? aiPromptResults.activities.value.toLowerCase() : '';
      const noteCreationDateLower = typeof noteCreationDate === 'string' ? noteCreationDate.toLowerCase() : '';
      const fromEmailLower = typeof fromEmail === 'string' ? fromEmail.toLowerCase() : '';
      const lastEditedLower = typeof lastEdited === 'string' ? lastEdited.toLowerCase() : '';
      const recTimesLower = typeof recTimes === 'string' ? recTimes.toLowerCase() : '';

      const filtersLower = Object.keys(filters).reduce((acc, key) => {
        acc[key] = typeof filters[key] === 'string' ? filters[key].toLowerCase() : '';
        return acc;
      }, {});

      const matchesColor = () => {
        const manualTagColor = manualPromptResults?.manualTags?.Categorization?.toLowerCase();
        const aiCategoryColor = aiPromptResults?.category?.[0]?.color?.value?.toLowerCase();
        const colorFilter = filtersLower.categoryColor;

        if (manualTagColor) {
          return manualTagColor === colorFilter;
        } else {
          return aiCategoryColor === colorFilter;
        }
      };

      const matchesFavorite = () => {
        const manualTagFavorite = manualPromptResults?.manualTags?.Favorite?.toLowerCase();
        return filtersLower.favorite ? manualTagFavorite === 'favorite' : true;
      };

      return (
        (!filtersLower.noteCreationDate || noteCreationDateLower.includes(filtersLower.noteCreationDate)) &&
        (!filtersLower.fromEmail || fromEmailLower.includes(filtersLower.fromEmail)) &&
        (!filtersLower.noteActive || noteActive?.toString() === filtersLower.noteActive) &&
        (!filtersLower.lastEdited || lastEditedLower.includes(filtersLower.lastEdited)) &&
        (!filtersLower.recTags || recTags.includes(filtersLower.recTags)) &&
        (!filtersLower.activities || activities.includes(filtersLower.activities)) &&
        (!filtersLower.bodyText || bodyText.includes(filtersLower.bodyText)) &&
        (!filtersLower.emailSubject || emailSubject.includes(filtersLower.emailSubject)) &&
        (!filtersLower.name || name.includes(filtersLower.name)) &&
        (!filtersLower.description || description.includes(filtersLower.description)) &&
        (!filtersLower.categoryColor || matchesColor()) &&
        (!filtersLower.categoryDescription || categoryDescription.includes(filtersLower.categoryDescription)) &&
        (!filtersLower.recTimes || recTimesLower.includes(filtersLower.recTimes)) &&
        matchesFavorite()
      );
    });

    setFilteredNotes(filtered);

    if (filtered.length > 0) {
      const lastNote = filtered[0];
      const address = lastNote.manualPromptResults?.manualTags?.Address || lastNote.aiPromptResults?.address?.value;
      const geolatLong = lastNote.aiPromptResults?.geolat_long?.value;
      //console.log("Change Map: setFilteredNotes: " + address);

      if (geolatLong) {
        centerMapOnAddress(address,geolatLong);
      }
      else if (address) {
        centerMapOnAddress(address);
      } else if (lastNote.aiPromptResults?.lat_long?.value) {
        const latLng = lastNote.aiPromptResults.lat_long.value.split(',').map(Number);
        //console.log("Change Map: setFilteredNotes: " + latLng[0] + "," + latLng[1]);
        setCenter({ lat: latLng[0], lng: latLng[1] });
        setZoom(DEFAULT_ZOOM);

        if (mapRef.current) {
          mapRef.current.panTo({ lat: latLng[0], lng: latLng[1] });
          mapRef.current.setZoom(DEFAULT_ZOOM);
        }
      }
    }
  };

  const clearFilters = async () => {
    setFilters({
      noteCreationDate: '',
      fromEmail: '',
      noteActive: '',
      lastEdited: '',
      recTags: '',
      activities: '',
      bodyText: '',
      emailSubject: '',
      name: '',
      description: '',
      categoryColor: '',
      categoryDescription: '',
      recTimes: ''
    });
    setFilteredNotes(notes);
    setSelectedFilter('');
    setColorFilter('');

    if (notes.length > 0) {
      const latestNote = notes[0];
      const address = latestNote.manualPromptResults?.manualTags?.Address || latestNote.aiPromptResults?.address?.value;

      if (address) {
        console.log("Gonna hit geocode: " + address);
        try {
          const location = await fetchGeolocationFromProvider(address);
          if (location) {
            setCenter(location);
            setZoom(5); // Adjust zoom level as needed
          
            if (mapRef.current) {
              mapRef.current.panTo({ lat: location.lat, lng: location.lng });
              mapRef.current.setZoom(DEFAULT_ZOOM);
            }

          } else {
            console.error(`Error geocoding address "${address}":`);
            setCenter(DEFAULT_CENTER);
            setZoom(DEFAULT_ZOOM);
            if (mapRef.current) {
              mapRef.current.panTo(DEFAULT_CENTER);
              mapRef.current.setZoom(DEFAULT_ZOOM);
            }
          }
        } catch (error) {
          console.error(`Error geocoding address "${address}": ${error.message}`);
          setCenter(DEFAULT_CENTER);
          setZoom(DEFAULT_ZOOM);
          if (mapRef.current) {
            mapRef.current.panTo(DEFAULT_CENTER);
            mapRef.current.setZoom(DEFAULT_ZOOM);
          }
        }
      } else if (latestNote.aiPromptResults?.lat_long?.value) {
        const latLng = latestNote.aiPromptResults.lat_long.value.split(',').map(Number);
        setCenter({ lat: latLng[0], lng: latLng[1] });
        setZoom(DEFAULT_ZOOM);

        if (mapRef.current) {
          mapRef.current.panTo({ lat: latLng[0], lng: latLng[1] });
          mapRef.current.setZoom(DEFAULT_ZOOM);
        }
      } else {
        setCenter(DEFAULT_CENTER);
        setZoom(DEFAULT_ZOOM);
        if (mapRef.current) {
          mapRef.current.panTo(DEFAULT_CENTER);
          mapRef.current.setZoom(DEFAULT_ZOOM);
        }
      }
    } else {
      setCenter(DEFAULT_CENTER);
      setZoom(DEFAULT_ZOOM);
      if (mapRef.current) {
        mapRef.current.panTo(DEFAULT_CENTER);
        mapRef.current.setZoom(DEFAULT_ZOOM);
      }
    }
  };

  const toggleFilters = () => {
    if (filtersVisible) {
      clearFilters();
    }
    setFiltersVisible(!filtersVisible);
  };

  const toggleNightMode = () => {
    setIsNightMode(!isNightMode);
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const currentNotes = filteredNotes.slice((currentPage - 1) * notesPerPage, currentPage * notesPerPage);

  const updateMapMarkers = async () => {
    if (mapRef.current) {
      if (mapRef.current.markers) {
        mapRef.current.markers.forEach(marker => marker.setMap(null));
        mapRef.current.markers = [];
      }
      //console.log("Here55");
      //console.log("Here55" + markers[0].lat + "," + markers[0].lng);
      
      await createAdvancedMarkers(mapRef.current, markers);
    }
  };

  const handleLogout = () => {
    signOut();
    navigate('/');
  };

  const handleCreateNoteClick = () => {
    //navigate('/create-note');
    ReactGA.event({
      category: 'User',
      action: 'Created New Note',
    });
    
    navigate('/create-note', {
      state: {
        clientId: connectionId,
      },
    });
  };

  const handleCreateNoteClickMap = async (position) => {
    try {
      const { lat, lng } = position;
      const address = await fetchAddressFromCoordinates(lat, lng); // Uses providerRef for fetching address
      navigate('/create-note', {
        state: {
          clientId: connectionId,
          manualTags: {
            Address: address.replace(/[^ -~]+/g, '').replace(/,/g, ''),
          },
          geolat_long: { value: `${lat},${lng}` },
        },
      });
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };
  
  

  const handleTabClick = (noteId, tabName) => {
    const key = `${noteId}-${tabName}`;
    setSelectedTab(selectedTab === key ? null : key);
  };

  const renderTabContent = (note, tabName) => {
    const aiResults = note.aiPromptResults || {};

    const normalizeKey = (key) => key.replace(/[\s:]/g, '').toLowerCase();

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //const hardcodedTimezone = 'America/Denver';

    // Convert and adjust the dates
    const creationDate = new Date(note.noteCreationDate);
    const lastEdited = new Date(note.lastEdited);
  
    //console.log('Original Creation Date:', creationDate);
    //console.log('Original Last Edited Date:', lastEdited);
  
    // Format dates to Mountain Time with 12-hour format and AM/PM
    const formattedCreationDate = formatInTimeZone(
      creationDate,
      userTimezone,
      'yyyy-MM-dd'
    );
    const formattedLastEdited = formatInTimeZone(
      lastEdited,
      userTimezone,
      'yyyy-MM-dd'
    );
  
    //console.log('Formatted Creation Date (Mountain Time):', formattedCreationDate);
    //console.log('Formatted Last Edited Date (Mountain Time):', formattedLastEdited);
    
    const renderObject = (obj, keyPrefix) => {
      const uniqueKeys = new Set();
      if (Array.isArray(obj)) {
        return obj
          .filter(item => item && item.value)
          .map((item, index) => {
            const key = `${keyPrefix}-${index}`;
            const normalizedKey = normalizeKey(item.value);
            if (uniqueKeys.has(normalizedKey)) return null;
            uniqueKeys.add(normalizedKey);
            return (
              <div key={key} className="blue-rectangle">
                {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, `${keyPrefix}-${index}`)}></button>}
                <span className="add-text">{item.value}</span>
              </div>
            );
          });
      } else if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj)
          .filter(([key, value]) => key !== 'probability' && value?.value)
          .filter(([key]) => {
            const normalizedKey = normalizeKey(key);
            if (uniqueKeys.has(normalizedKey)) return false;
            uniqueKeys.add(normalizedKey);
            return true;
          })
          .map(([key, value], index) => {
            const elementKey = `${keyPrefix}-${key}`;
            return (
              <div key={elementKey} className="blue-rectangle">
                {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, `${keyPrefix}-${key}`)}></button>}
                <span>{`${key}: ${value.value}`}</span>
              </div>
            );
          });
      }
      return null;
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString();
    };

    const renderRecTimes = () => {
      if (Array.isArray(aiResults.recTimes)) {
        return aiResults.recTimes
          .filter(time => time && (time['best season to visit']?.value || time['best day to visit']?.value || time['best time to visit']?.value || time.reason?.value))
          .map((time, index) => {
            const key = `recTimes-${index}`;
            return (
              <div key={key} className="blue-rectangle">
                {editingNoteId === note.noteID && (
                  <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, 'recTimes')}></button>
                )}
                <span>
                  {time['best season to visit'] && (
                    <div>
                      <span className="highlight-text">Best season to visit: </span>
                      {time['best season to visit'].value}
                    </div>
                  )}
                  {time['best day to visit'] && (
                    <div>
                      <span className="highlight-text">Best day to visit: </span>
                      {time['best day to visit'].value}
                    </div>
                  )}
                  {time['best time to visit'] && (
                    <div>
                      <span className="highlight-text">Best time to visit: </span>
                      {time['best time to visit'].value}
                    </div>
                  )}
                  {time.reason && (
                    <div>
                      <span className="highlight-text">Reason: </span>
                      {time.reason.value}
                    </div>
                  )}
                </span>
              </div>
            );
          });
      } else if (typeof aiResults.recTimes === 'object') {
        return (
          <div className="blue-rectangle">
            {editingNoteId === note.noteID && (
              <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, 'recTimes')}></button>
            )}
            <span>
              {aiResults.recTimes['best season to visit'] && (
                <div>
                  <span className="highlight-text">Best season to visit: </span>
                  {aiResults.recTimes['best season to visit'].value}
                </div>
              )}
              {aiResults.recTimes['best day to visit'] && (
                <div>
                  <span className="highlight-text">Best day to visit: </span>
                  {aiResults.recTimes['best day to visit'].value}
                </div>
              )}
              {aiResults.recTimes['best time to visit'] && (
                <div>
                  <span className="highlight-text">Best time to visit: </span>
                  {aiResults.recTimes['best time to visit'].value}
                </div>
              )}
              {aiResults.recTimes.reason && (
                <div>
                  <span className="highlight-text">Reason: </span>
                  {aiResults.recTimes.reason.value}
                </div>
              )}
            </span>
          </div>
        );
      }
      return null;
    };

    switch (tabName) {
      case 'Note Details':
        return [
          note.noteCreationDate && (
            <div key="dateCreated" className="blue-rectangle">
              <span><span className="highlight-text">Date Created: </span>{formattedCreationDate}</span>
              
            </div>
          ),
          note.lastEdited && (
            <div key="lastUpdated" className="blue-rectangle">
              <span><span className="highlight-text">Last Updated: </span>{formattedLastEdited}</span>
            </div>
          ),
          note.fromEmail && (
            <div key="fromEmail" className="blue-rectangle">
              <span><span className="highlight-text">From Email: </span>{note.fromEmail}</span>
            </div>
          ),
          note.noteActive != null && (
            <div key="activeState" className="blue-rectangle">
              <span><span className="highlight-text">Active State: </span>{note.noteActive ? 'True' : 'False'}</span>
            </div>
          )
        ].filter(element => element);

      case 'My Tags':
        return [
          ...Object.entries(note.manualPromptResults?.manualTags || {})
            .filter(([key, value]) => value)
            .map(([key, value]) => {
              const elementKey = `manualTags-${key}`;
              return (
                <div key={elementKey} className="blue-rectangle">
                  {editingNoteId === note.noteID && (
                    <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, 'My Tags', key)}></button>
                  )}
                  <span>
                    <span className="highlight-text">{key}:</span> {value}
                  </span>
                </div>
              );
            }),
          editingNoteId === note.noteID && newBox.visible && (
            <div key="new-tag" className="blue-rectangle new-rectangle">
              <input
                type="text"
                className="new-rectangle-input"
                placeholder="Key"
                value={newBox.key}
                onChange={(e) => setNewBox({ ...newBox, key: e.target.value })}
              />
              {(newBox.key.toLowerCase() === 'categorization' || newBox.key.toLowerCase() === 'color') && (
                <select
                  className="new-rectangle-input"
                  value={newBox.value}
                  onChange={(e) => setNewBox({ ...newBox, value: e.target.value })}
                >
                  <option value="">Select Color</option>
                  {colorOptions.map((color) => (
                    <option key={color} value={color}>
                      {color}
                    </option>
                  ))}
                </select>
              )}
              {newBox.key.toLowerCase() !== 'categorization' && newBox.key.toLowerCase() !== 'color' && (
                <input
                  type="text"
                  className="new-rectangle-input"
                  placeholder="Value"
                  value={newBox.value}
                  onChange={(e) => setNewBox({ ...newBox, value: e.target.value })}
                />
              )}
              <button className="save-new-rectangle-button" onClick={() => handleSaveNewBox(note.noteID, 'My Tags')}>Save</button>
              <span className="tooltip-container">
                      <span className="tooltip-icon"></span>
                      <span className="tooltip-text">
                        <ul>
                          <li>Add "address" to overwrite or change the location.</li>
                          <li>Add "color or categorization" to overwrite or change the color.</li>
                          <li>Add your own tags.</li>
                        </ul>
                      </span>
              </span>
            </div>
          )
        ].filter(element => element);

      case 'Recommendations':
        return [
          aiResults.name?.value && (
            <div key="name" className="blue-rectangle">
              {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, 'name')}></button>}
              <span><span className="highlight-text">Name: </span>{aiResults.name.value}</span>
            </div>
          ),
          aiResults.description?.value && (
            <div key="description" className="blue-rectangle">
              {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, 'description')}></button>}
              <span><span className="highlight-text">Description: </span>{aiResults.description.value}</span>
            </div>
          ),
          aiResults.address?.value && (
            <div key="address" className="blue-rectangle">
              {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, 'address')}></button>}
              <span><span className="highlight-text">Address: </span>{aiResults.address.value}</span>
            </div>
          ),
          Array.isArray(aiResults.activities?.value) && aiResults.activities.value.length > 0 && (
            <div key="activities" className="blue-rectangle">
              {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, 'activities')}></button>}
              <span><span className="highlight-text">Activities: </span>{aiResults.activities.value.join(', ')}</span>
            </div>
          ),
          Array.isArray(aiResults.activities) && aiResults.activities.length > 0 && (
            <div key="activities" className="blue-rectangle">
              {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, 'activities')}></button>}
              <span><span className="highlight-text">Activities: </span>{aiResults.activities.map(activity => activity.value).join(', ')}</span>
            </div>
          ),
          Array.isArray(aiResults.recTags?.value) && aiResults.recTags.value.length > 0 && (
            <div key="recTags" className="blue-rectangle">
              {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, 'recTags')}></button>}
              <span><span className="highlight-text">Recommended Tags: </span>{aiResults.recTags.value.join(', ')}</span>
            </div>
          ),
          Array.isArray(aiResults.recTags) && aiResults.recTags.length > 0 && (
            <div key="recTags" className="blue-rectangle">
              {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, 'recTags')}></button>}
              <span><span className="highlight-text">Recommended Tags: </span>{aiResults.recTags.map(tag => tag.value).join(', ')}</span>
            </div>
          ),
          aiResults.category?.[0]?.color?.value && (
            <div key="category" className="blue-rectangle">
              {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, 'category')}></button>}
              <span><span className="highlight-text">Categorization: </span>{aiResults.category[0].color.value}</span>
            </div>
          ),
          Array.isArray(aiResults.nearby?.value) && aiResults.nearby.value.length > 0 && (
            <div key="nearby" className="blue-rectangle">
              {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, 'nearby')}></button>}
              <span><span className="highlight-text">Nearby things to do: </span>{aiResults.nearby.value.join(', ')}</span>
            </div>
          ),
          Array.isArray(aiResults.nearby) && aiResults.nearby.length > 0 && (
            <div key="nearby" className="blue-rectangle">
              {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, 'nearby')}></button>}
              <span><span className="highlight-text">Nearby things to do: </span>{aiResults.nearby.map(location => location.value).join(', ')}</span>
            </div>
          ),
          ...renderObject(aiResults.lat_long, 'lat_long'),
          renderRecTimes(),
          ...Object.entries(note.aiPromptResults || {})
            .filter(([key, value]) => value && value.value && !['name', 'address', 'recTags', 'activities','lat_long', 'geolat_long','description', 'nearby'].includes(key.toLowerCase()))
            .reduce((acc, [key, value]) => {
              const normalizedKey = normalizeKey(key);
              if (!acc.some(([existingKey]) => normalizeKey(existingKey) === normalizedKey)) {
                acc.push([key, value]);
              }
              return acc;
            }, [])
            .map(([key, value], index) => {
              const elementKey = `aiPromptResults-${key}`;
              return (
                <div key={elementKey} className="blue-rectangle">
                  {editingNoteId === note.noteID && <button className="delete-box-button" onClick={() => handleDeleteBox(note.noteID, tabName, `${elementKey}-${index}`)}></button>}
                  <span><span className="highlight-text">{key}:</span> {value.value}</span>
                </div>
              );
            })
        ].filter(element => element);
      default:
        return [];
    }
  };

  const handleDeleteNote = async (noteId, lastEdited) => {
    const confirmed = window.confirm("Are you sure you want to delete this note?");
    if (confirmed) {
      try {
        const token = (await fetchAuthSession()).tokens?.idToken?.toString();
        const headers = {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${token}`
        };

        //await axios.delete(`https://rccd7493ld.execute-api.us-west-2.amazonaws.com/dev/notes/${noteId}?lastEdited=${encodeURIComponent(lastEdited)}`, { headers });
        await axios.delete(`${apiUrl}/notes/${noteId}?lastEdited=${encodeURIComponent(lastEdited)}`, { headers });
        fetchNotes();
      } catch (error) {
        console.error('Error deleting note:', error);
      }
    }
  };

  const handleEditNote = (noteId, currentBody, currentLink, currentEmailSubject) => {
    setEditingNoteId(noteId);
    setEditedNote(currentBody);
    setEditedLink(currentLink);
    setEditedEmailSubject(currentEmailSubject);
    setOriginalNote(currentBody);
  };

  const handleSaveNote = async (noteId) => {
    try {
      const token = (await fetchAuthSession()).tokens?.idToken?.toString();
  
      const headers = {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${token}`
      };
  
      const noteToSave = notes.find(note => note.noteID === noteId);
      if (!noteToSave) {
        console.error('Note not found:', noteId);
        return;
      }
  
      const { noteCreationDate, lastEdited, fromEmail, noteActive } = noteToSave;
      const manualTags = noteToSave.manualPromptResults?.manualTags || {};
      const aiPromptResults = noteToSave.aiPromptResults || {};
  
      const noteDetails = {
        ...(noteCreationDate && { dateCreated: formatTimestamp(new Date(noteCreationDate)) }),
        ...(lastEdited && { lastUpdated: formatTimestamp(new Date(lastEdited)) }),
        ...(fromEmail && { fromEmail }),
        ...(editedEmailSubject && { emailSubject: editedEmailSubject }),
        ...(noteActive != null && { activeState: noteActive ? 'True' : 'False' })
      };
  
      const tags = Object.entries(manualTags).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  
      const aiRecommendations = {
        ...aiPromptResults,
        geolat_long: aiPromptResults.geolat_long || { value: '' }, // Ensure geolat_long is included
        address: aiPromptResults.address || { value: '', probability: 0 },
        recTags: Array.isArray(aiPromptResults.recTags) ? aiPromptResults.recTags : [],
        activities: Array.isArray(aiPromptResults.activities) ? aiPromptResults.activities : [],
        lat_long: aiPromptResults.lat_long || { value: '', probability: 0 },
        name: aiPromptResults.name || { value: '', probability: 0 },
        description: aiPromptResults.description || { value: '', probability: 0 },
        recTimes: Array.isArray(aiPromptResults.recTimes) ? aiPromptResults.recTimes : [],
        category: Array.isArray(aiPromptResults.category) ? aiPromptResults.category : [],
        nearby: Array.isArray(aiPromptResults.nearby) ? aiPromptResults.nearby : []
      };
  
      const updatedTime = formatTimestamp(new Date());
  
      const urlsArray = noteToSave.manualPromptResults?.url || [];
      const urlsPresent = urlsArray.length > 0;

      const newAddress = manualTags.Address;
      const previousAddress = aiPromptResults.address?.value;
      let latLng; // Variable to store the new geolocation coordinates

      if (newAddress && (newAddress !== previousAddress)) {
        console.log("checked new address and hitting geocode: " + newAddress);
        console.log("checked old address and hitting geocode: " + previousAddress);
        console.log(`Updating geolat_long for note ${noteId}: ${aiRecommendations.geolat_long.value}`);

        try {
          const location = await fetchGeolocationFromProvider(newAddress);
          if (location) {
            latLng = [location.lat, location.lng];

            // Update the geolat_long value
            aiRecommendations.geolat_long = { value: `${latLng[0]},${latLng[1]}` };

            // Update the note with the new geolat_long
            //await updateNoteWithGeolatLong(noteId, latLng, noteToSave);
          } else {
            console.error(`Error geocoding address "${newAddress}":`);
          }
        } catch (error) {
          console.error('Error geocoding address:', error.message);
        }
      }
      console.log(`Updated geolat_long for note ${noteId}: ${aiRecommendations.geolat_long.value}`);


      const combinedBodyText  = JSON.stringify({
        bodyText: editedNote,
        //url: editedLink,
        url: urlsArray,
        urlsPresent: urlsPresent,
        noteDetails,
        tags,
        aiRecommendations,
        //clientId: connectionId,
        lastEdited
      }, null, 2);
  
      await axios.put(
        //`https://rccd7493ld.execute-api.us-west-2.amazonaws.com/dev/notes/${noteId}`,
        `${apiUrl}/notes/${noteId}`,
        { bodyText: combinedBodyText   },
        { headers }
      );
  
      // Update the markers after the note has been saved
      if (latLng) {
        console.log(`Updated markers for ${noteId}: ` + latLng);
        updateMarkers(noteId, latLng);
      }
      setEditingNoteId(null);
      fetchNotes();
    } catch (error) {
      console.error('Error saving note:', error);
    }
  };
  
  // Function to update the markers list and remove the old marker
  const updateMarkers = (noteId, latLng) => {
    // Remove old markers from the map instance
    if (mapRef.current && mapRef.current.markers) {
      // Find the marker to be removed
      const markersToRemove = mapRef.current.markers.filter((marker) => marker.noteID === noteId);
  
      // Remove each marker from the map
      markersToRemove.forEach((marker) => marker.setMap(null));
  
      // Update the markers array on the mapRef instance
      mapRef.current.markers = mapRef.current.markers.filter((marker) => marker.noteID !== noteId);
    }
  
    // Update the state with new markers
    setMarkers((prevMarkers) => {
      // Filter out the old marker with the same noteId
      const filteredMarkers = prevMarkers.filter((marker) => marker.noteID !== noteId);
  
      // Create the new marker for the updated note
      const updatedMarker = {
        lat: latLng[0],
        lng: latLng[1],
        noteID: noteId,
        title: notes.find((note) => note.noteID === noteId)?.manualPromptResults?.emailSubject,
        body: notes.find((note) => note.noteID === noteId)?.manualPromptResults?.url,
        category: notes.find((note) => note.noteID === noteId)?.manualPromptResults?.manualTags?.Categorization ||
          notes.find((note) => note.noteID === noteId)?.aiPromptResults?.category?.[0]?.color?.value,
      };
  
      // Add the new marker to the markers array
      const newMarkers = [...filteredMarkers, updatedMarker];
  
      // Add the new marker to the map instance
      if (mapRef.current) {
        createAdvancedMarkers(mapRef.current, newMarkers);
      }
  
      return newMarkers;
    });
  
    // Recenter the map to the new location
    setCenter({ lat: latLng[0], lng: latLng[1] });
    setZoom(12);
  
    if (mapRef.current) {
      mapRef.current.panTo({ lat: latLng[0], lng: latLng[1] });
      mapRef.current.setZoom(12);
    }
  };

  const handleCancelEdit = () => {
    setEditingNoteId(null);
    setEditedNote(originalNote);
  };

  const handleDeleteBox = (noteId, tabName, boxKey) => {
    setNotes((prevNotes) => {
      return prevNotes.map(note => {
        if (note.noteID === noteId) {
          const updatedNote = { ...note };

          if (tabName === 'Note Details') {
            if (boxKey === 'dateCreated') {
              delete updatedNote.noteCreationDate;
            } else if (boxKey === 'lastUpdated') {
              delete updatedNote.lastEdited;
            } else if (boxKey === 'fromEmail') {
              delete updatedNote.fromEmail;
            } else if (boxKey === 'activeState') {
              delete updatedNote.noteActive;
            }
          } else if (tabName === 'My Tags') {
            if (updatedNote.manualPromptResults && updatedNote.manualPromptResults.manualTags) {
              const manualTags = { ...updatedNote.manualPromptResults.manualTags };
              if (manualTags[boxKey]) {
                delete manualTags[boxKey];
                updatedNote.manualPromptResults.manualTags = manualTags;
              }
            }
          } else if (tabName === 'Recommendations') {
            if (boxKey === 'name') {
              delete updatedNote.aiPromptResults.name;
            } else if (boxKey === 'description') {
              delete updatedNote.aiPromptResults.description;
            } else if (boxKey === 'address') {
              delete updatedNote.aiPromptResults.address;
            } else if (boxKey === 'activities') {
              delete updatedNote.aiPromptResults.activities;
            } else if (boxKey === 'recTags') {
              delete updatedNote.aiPromptResults.recTags;
            } else if (boxKey === 'category') {
              delete updatedNote.aiPromptResults.category;
            } else if (boxKey === 'nearby') {
              delete updatedNote.aiPromptResults.nearby;
            } else if (boxKey === 'recTimes') {
              delete updatedNote.aiPromptResults.recTimes;
            } else if (updatedNote.aiPromptResults.lat_long && Array.isArray(updatedNote.aiPromptResults.lat_long)) {
              updatedNote.aiPromptResults.lat_long.splice(boxKey, 1);
            }
          }
          return updatedNote;
        }
        return note;
      });
    });
  };

  const handleFavoriteToggle = async (noteId) => {
    const updatedNotes = notes.map((note) => {
      if (note.noteID === noteId) {
        if (note.manualPromptResults?.manualTags?.Favorite) {
          delete note.manualPromptResults.manualTags.Favorite;
        } else {
          if (!note.manualPromptResults) {
            note.manualPromptResults = {};
          }
          if (!note.manualPromptResults.manualTags) {
            note.manualPromptResults.manualTags = {};
          }
          note.manualPromptResults.manualTags.Favorite = 'Favorite';
        }
      }
      return note;
    });

    setNotes(updatedNotes);

    try {
      const token = (await fetchAuthSession()).tokens?.idToken?.toString();

      const headers = {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${token}`
      };

      const noteToUpdate = updatedNotes.find(note => note.noteID === noteId);
      if (!noteToUpdate) {
        console.error('Note not found:', noteId);
        return;
      }

      const { noteCreationDate, lastEdited, fromEmail, noteActive, manualPromptResults } = noteToUpdate;
      const emailSubject = manualPromptResults?.emailSubject;
      const manualTags = noteToUpdate.manualPromptResults?.manualTags || {};
      const aiPromptResults = noteToUpdate.aiPromptResults || {};

      const noteDetails = {
        ...(noteCreationDate && { dateCreated: formatTimestamp(new Date(noteCreationDate)) }),
        ...(lastEdited && { lastUpdated: formatTimestamp(new Date(lastEdited)) }),
        ...(fromEmail && { fromEmail }),
        ...(emailSubject && { emailSubject }),
        ...(noteActive != null && { activeState: noteActive ? 'True' : 'False' })
      };

      const tags = Object.entries(manualTags).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

      const aiRecommendations = {
        address: aiPromptResults.address || { value: '', probability: 0 },
        geolat_long: aiPromptResults.geolat_long || { value: '' }, // Ensure geolat_long is included
        recTags: Array.isArray(aiPromptResults.recTags) ? aiPromptResults.recTags : [],
        activities: Array.isArray(aiPromptResults.activities) ? aiPromptResults.activities : [],
        lat_long: aiPromptResults.lat_long || { value: '', probability: 0 },
        name: aiPromptResults.name || { value: '', probability: 0 },
        description: aiPromptResults.description || { value: '', probability: 0 },
        recTimes: Array.isArray(aiPromptResults.recTimes) ? aiPromptResults.recTimes : [],
        category: Array.isArray(aiPromptResults.category) ? aiPromptResults.category : [],
        nearby: Array.isArray(aiPromptResults.nearby) ? aiPromptResults.nearby : []
      };

      const updatedTime = formatTimestamp(new Date());

      const urlsArray = noteToUpdate.manualPromptResults?.url || [];
      const urlsPresent = urlsArray.length > 0;

      const combinedBodyText  = JSON.stringify({
        bodyText: noteToUpdate.manualPromptResults?.bodyText || '',
        url: urlsArray,
        urlsPresent: urlsPresent,
        noteDetails,
        tags,
        aiRecommendations,
        //clientId: connectionId, // Use clientId.current here
        lastEdited
      }, null, 2);

      await axios.put(
        //`https://rccd7493ld.execute-api.us-west-2.amazonaws.com/dev/notes/${noteId}`,
        `${apiUrl}/notes/${noteId}`,
        { bodyText: combinedBodyText },
        { headers }
      );

    } catch (error) {
      console.error('Error updating favorite status:', error);
    }
  };

  const handleMapClick = async (e) => {
    // Check if the clicked event contains latLng and pixel information
    if (!e.latLng) {
      console.warn('Map click event does not contain latLng. This might be a click on a Google Maps icon.');
      return; // Exit early if latLng is not available
    }
  
    // Fetch latitude and longitude from the clicked position
    const latLng = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
  
    // Check if pixel information is available
    if (!e.pixel) {
      console.warn('Map click event does not contain pixel information.');
      // Fetch place details using Google's Places API
      const service = new window.google.maps.places.PlacesService(mapRef.current);
      const request = {
        location: new window.google.maps.LatLng(latLng.lat, latLng.lng),
        radius: '50' // Radius to search for place details
      };
  
      service.nearbySearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && results[0]) {
          const place = results[0];
          console.log('Place details:', place);
          //alert(`Place: ${place.name}\nAddress: ${place.vicinity}`);
        } else {
          console.warn('No place details found.');
        }
      });
  
      return; // Exit after displaying place details
    }
  
    // If pixel information is available, handle the map click normally
    const x = e.pixel.x;
    const y = e.pixel.y;
  
    setClickPosition((prevPosition) => {
      if (prevPosition) {
        return null;
      }
      return { lat: latLng.lat, lng: latLng.lng, x, y };
    });
  };

    // Example function to update plan and provider based on user interaction
  const updatePlanAndProvider = (newPlan) => {
      setPlan(newPlan);
      // Update the provider based on the plan
      const newProvider = newPlan === 'Basic' ? 'mapbox' : 'google';
      setProvider(newProvider);
      console.log(`Plan updated to ${newPlan}, provider set to ${newProvider}`);
    };
  
      // Track page views on route changes
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  useEffect(() => {
      // Fetch initial data and set default provider based on plan
      setProvider(plan === 'Basic' ? 'mapbox' : 'google');
      //setProvider('google');
      providerRef.current = plan === 'Basic' ? 'mapbox' : 'google';
    }, [plan]);

  useEffect(() => {
      // Fetch user info on component mount
      fetchUserInfo();
    }, []);

  // Sync the provider state to providerRef
  useEffect(() => {
      providerRef.current = provider;
    }, [provider]);

  useEffect(() => {
    //console.error('UseEffect1: ');
    clientIdRef.current = clientId;
  }, [clientId]);

  useEffect(() => {
    const initializeMapCenter = async () => {
      //console.error('UseEffect2: ');
      if (notes.length > 0) {
        // Sort notes by lastEdited in descending order to get the most recent one
        const lastEditedNote = notes.sort((a, b) => new Date(b.lastEdited) - new Date(a.lastEdited))[0];
  
        const geolatLong = lastEditedNote.aiPromptResults?.geolat_long?.value;
        const address = lastEditedNote.manualPromptResults?.manualTags?.Address || lastEditedNote.aiPromptResults?.address?.value;
        //console.error('UseEffect2: ' + lastEditedNote.aiPromptResults?.geolat_long?.value);
        //console.error('UseEffect2: ');
        if (geolatLong) {
          const latLng = geolatLong.split(',').map(Number);
          //console.log("Change lat long1?: " + latLng[0] +","+latLng[1]);
          setCenter({ lat: latLng[0], lng: latLng[1] });
          setZoom(DEFAULT_ZOOM);
  
          if (mapRef.current) {
            //console.log("Change lat long2?: " + address);
            //console.log("Change lat long3?: " + latLng[0] +","+latLng[1]);
            mapRef.current.panTo({ lat: latLng[0], lng: latLng[1] });
            mapRef.current.setZoom(DEFAULT_ZOOM);
          }
        } else if (address) {
          try {
            console.log("Gonna hit geocode3: " + address);
            const location = await fetchGeolocationFromProvider(address);
            console.log("Gonna hit geocode3: " + location.lat + "," + location.lng);
            console.log("Gonna hit geocode3: " + location);
            if (location) {
              setCenter({ lat: location.lat, lng: location.lng });
              setZoom(5); // Adjust zoom level as needed

              if (mapRef.current) {
                //console.log("changing latlong again5: " + location[1] + "," + location[0]);
                mapRef.current.panTo({ lat: location.lat, lng: location.lng  });
                mapRef.current.setZoom(DEFAULT_ZOOM);
              }
            } else {
              console.error(`Error geocoding address "${address}":`);
              setCenter(DEFAULT_CENTER);
              setZoom(DEFAULT_ZOOM);
            }
          } catch (error) {
            console.error(`Error geocoding address "${address}": ${error.message}`);
            setCenter(DEFAULT_CENTER);
            setZoom(DEFAULT_ZOOM);
          }
        } else {
          setCenter(DEFAULT_CENTER);
          setZoom(DEFAULT_ZOOM);
        }
      } else {
        setCenter(DEFAULT_CENTER);
        setZoom(DEFAULT_ZOOM);
      }
    };
  
    // Call the function once notes are fetched and loaded
    if (notes.length > 0) {
      initializeMapCenter();
    }
  }, [notes.length]);
  

  useEffect(() => {
    //console.error('UseEffect3: ');
    console.log("API URL:", process.env.REACT_APP_API_URL);
    console.log("Environment:", process.env.REACT_APP_ENVIRONMENT);
    fetchNotes();
  }, []);


  useEffect(() => {
    if (socket) {
        socket.onmessage = async (event) => {
            try {
                const data = JSON.parse(JSON.parse(event.data));

                if (data.type === 'newNote' && data.clientId === connectionId) {
                    const newNote = data.note;
                    
                    if (!newNote.aiPromptResults?.geolat_long?.value) {
                        const address = newNote.manualPromptResults?.manualTags?.Address || newNote.aiPromptResults?.address?.value;
                        
                        let latLng;

                        if (address) {
                          const location = await fetchGeolocationFromProvider(address);
                          if (location) {
                            latLng = [location.lat, location.lng];
                          }
                          await updateNoteWithGeolatLong(newNote.noteID, latLng, newNote);
                          newNote.aiPromptResults.geolat_long = { value: `${latLng[0]},${latLng[1]}` };
                            
                        }
                    }

                    setNotes((prevNotes) => {
                        const updatedNotes = [...prevNotes, newNote];
                        setFilteredNotes(applyFiltersToNewNote(newNote, updatedNotes));
                        return updatedNotes;
                    });

                    await updateMarkersWithNewNote(newNote);
                    setCurrentPage(1);
                }
            } catch (error) {
                console.error('Error handling WebSocket message:', error);
            }
        };
    }
}, [socket, connectionId]);


const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const debouncedUpdateNoteWithGeolatLong = debounce(updateNoteWithGeolatLong, 1000); 


const applyFiltersToNewNote = (newNote, updatedNotes) => {
    const matchesFilters = (note) => {
        const { manualPromptResults, aiPromptResults, noteCreationDate, fromEmail, noteActive, lastEdited } = note;

        const bodyText = manualPromptResults?.bodyText?.toLowerCase() || '';
        const emailSubject = manualPromptResults?.emailSubject?.toLowerCase() || '';
        const name = aiPromptResults?.name?.value?.toLowerCase() || '';
        const description = aiPromptResults?.description?.value?.toLowerCase() || '';
        const categoryColor = manualPromptResults?.manualTags?.Categorization?.toLowerCase() ||
            aiPromptResults?.category?.[0]?.color?.value?.toLowerCase() || '';
        const lastEditedLower = lastEdited?.toLowerCase() || '';
        const fromEmailLower = fromEmail?.toLowerCase() || '';
        const noteCreationDateLower = noteCreationDate?.toLowerCase() || '';

        const matchesColor = filters.categoryColor ? categoryColor === filters.categoryColor.toLowerCase() : true;
        const matchesFavorite = filters.favorite ? manualPromptResults?.manualTags?.Favorite?.toLowerCase() === 'favorite' : true;

        return (
            (!filters.noteCreationDate || noteCreationDateLower.includes(filters.noteCreationDate.toLowerCase())) &&
            (!filters.fromEmail || fromEmailLower.includes(filters.fromEmail.toLowerCase())) &&
            (!filters.noteActive || noteActive?.toString() === filters.noteActive) &&
            (!filters.lastEdited || lastEditedLower.includes(filters.lastEdited.toLowerCase())) &&
            (!filters.bodyText || bodyText.includes(filters.bodyText.toLowerCase())) &&
            (!filters.emailSubject || emailSubject.includes(filters.emailSubject.toLowerCase())) &&
            (!filters.name || name.includes(filters.name.toLowerCase())) &&
            (!filters.description || description.includes(filters.description.toLowerCase())) &&
            matchesColor &&
            matchesFavorite
        );
    };

    // Apply filters to all notes, including the new note
    const filtered = updatedNotes.filter(matchesFilters);
    return filtered;
};

const updateMarkersWithNewNote = async (newNote) => {
  const geolatLong = newNote.aiPromptResults?.geolat_long?.value;
  const address = newNote.manualPromptResults?.manualTags?.Address || newNote.aiPromptResults?.address?.value;

  let latLng;

  if (geolatLong) {
    latLng = geolatLong.split(',').map(Number);
  } else if (address) {
    try {
      const location = await fetchGeolocationFromProvider(address);
      if (location) {
        latLng = [location.lat, location.lng];
      }
    } catch (error) {
      console.error('Error geocoding address:', error);
      return;
    }
  }

  if (latLng) {
    const marker = {
      lat: latLng[0],
      lng: latLng[1],
      noteID: newNote.noteID,
      title: newNote.manualPromptResults?.emailSubject,
      body: newNote.manualPromptResults?.url,
      category: newNote.manualPromptResults?.manualTags?.Categorization || newNote.aiPromptResults?.category?.[0]?.color?.value,
    };
    setMarkers((prevMarkers) => [...prevMarkers, marker]);
    setCenter({ lat: latLng[0], lng: latLng[1] });
    setZoom(12);

    if (mapRef.current) {
      mapRef.current.panTo({ lat: latLng[0], lng: latLng[1] });
      mapRef.current.setZoom(12);
    }
  }
};



  
useEffect(() => {
  if (notes.length > 0) {
    //console.error('UseEffect4: ');
    applyFilters();
  }
}, [notes, filters]);


  useEffect(() => {
    if (mapRef.current) {
      //console.error('UseEffect6: ');
      updateMapMarkers();
    }
  }, [filteredNotes, markers]);

  useEffect(() => {
    const root = document.documentElement;
    if (isNightMode) {
      root.style.setProperty('--bg-color', '#333');
      root.style.setProperty('--text-color', '#fff');
      root.style.setProperty('--sidebar-bg-color', '#444');
      root.style.setProperty('--note-bg-color', '#555');
      root.style.setProperty('--button-bg-color', '#555');
      root.style.setProperty('--button-hover-bg-color', '#666');
    } else {
      root.style.setProperty('--bg-color', '#fff');
      root.style.setProperty('--text-color', '#000');
      root.style.setProperty('--sidebar-bg-color', '#f8f9fa');
      root.style.setProperty('--note-bg-color', '#fff');
      root.style.setProperty('--button-bg-color', '#007bff');
      root.style.setProperty('--button-hover-bg-color', '#0056b3');
    }
  }, [isNightMode]);

  useEffect(() => {
    if (isLoaded && mapRef.current) {
      //console.error('UseEffect7: ');
      const initMap = async () => {
        mapRef.current.markers = [];
        await createAdvancedMarkers(mapRef.current, markers);
        //mapRef.current.panTo(DEFAULT_CENTER);
        mapRef.current.setZoom(DEFAULT_ZOOM);
      };
      initMap();
    }
  }, [isLoaded, markers]);

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  return (
    <div className={`App ${isNightMode ? 'night-mode' : ''}`}>
      <div className="banner">
        <div className="banner-left">
          <a href="/"><img src="./logo.png" alt="Ideal Scribe" className="logo" /></a>
          <Link to="/" className="title-link">
            <h1 className="title">Ideal Scribe</h1>
          </Link>
          <span className="beta-label">beta</span>
        </div>
        <nav className="nav-links">
          <button className="filter-toggle" onClick={toggleFilters}>
            {filtersVisible ? 'Hide Filters' : 'Show Filters'}
          </button>
          {/*<button className="night-mode-toggle" onClick={toggleNightMode}>
            {isNightMode ? 'Day Mode' : 'Night Mode'}
          </button>*/}
          <button className="night-mode-toggle" onClick={handleLogout}>Logout</button>
          <Link to="/account-settings">Account Settings</Link>
          <Link to="/startup-guide">Startup Guide</Link>
        </nav>
      </div>
      <div className="main-content">
        <div className="sidebar" style={{ paddingBottom: 100 }}>
          <h1 style={{ position: 'relative' }}>My Quicknotes
            <button className="create-note-button-circle" onClick={handleCreateNoteClick}>+</button>
            <button
              className={`filter-icon ${filtersVisible ? 'active' : ''}`}
              onClick={toggleFilters}
            ></button>
          </h1>
          {filtersVisible && (
            <div className="filters">
              <select className="filter-select-modern" onChange={(e) => handleFilterSelect(e.target.value)}>
                <option value="">Select Filter</option>
                <option value="emailSubject">Email Subject or Note Name</option>
                <option value="bodyText">Note Text</option>
                <option value="categoryColor">Color</option>
                <option value="favorite">Favorite</option>
                <option value="noteCreationDate">Note Creation Date</option>
                <option value="lastEdited">Last Edited</option>
                <option value="fromEmail">From Email</option>
                <option value="noteActive">Note Active</option>
                <option value="name">Recommended Name</option>
                <option value="description">Recommended Description</option>
              </select>
              {selectedFilter && selectedFilter !== 'favorite' && (
                selectedFilter === 'categoryColor' ? (
                  <select
                    name="categoryColor"
                    className="filter-input-modern"
                    value={colorFilter}
                    onChange={handleFilterChange}
                  >
                    <option value="">Select Color</option>
                    {colorOptions.map(color => (
                      <option key={color} value={color.toLowerCase()}>{color}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    name={selectedFilter}
                    className="filter-input-modern"
                    placeholder={
                      selectedFilter === "noteCreationDate" || selectedFilter === "lastEdited"
                        ? "yyyy-mm-dd"
                        : selectedFilter === "noteActive"
                          ? "true or false"
                          : `Filter by ${selectedFilter}`
                    }
                    onChange={handleFilterChange}
                  />
                )
              )}
            </div>
          )}
          <ul>
            {currentNotes.map((item) => (
              <li key={item.noteID} className="note" onClick={() => handleNoteSelect(item)}>
                <div className="note-buttons">
                  <button
                    className="note-button note-button-edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditNote(
                        item.noteID,
                        item.manualPromptResults?.bodyText || '',
                        item.manualPromptResults?.url || '',
                        item.manualPromptResults?.emailSubject || ''
                      );
                    }}
                  ></button>
                  <button
                    className="note-button note-button-delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteNote(item.noteID, item.lastEdited);
                    }}
                  ></button>
                  <button
                    className={`note-button note-button-favorite ${item.manualPromptResults?.manualTags?.Favorite ? 'favorited' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFavoriteToggle(item.noteID);
                    }}
                  ></button>
                  {editingNoteId === item.noteID && (
                    <>
                      <button className="add-new-box-button" onClick={(e) => {
                        e.stopPropagation();
                        handleAddNewBox(item.noteID, 'Recommendations');
                      }}></button>
                      <span className="add-tag">Add Tag</span>
                    </>
                  )}
                </div>
                {editingNoteId !== item.noteID && (
                  <div className="note-content">
                    <div className="note-title">{item.manualPromptResults?.emailSubject}</div>
                    <div className="note-body">
                      {truncateText(item.manualPromptResults?.bodyText || '', 30)}
                    </div>
                    {item.manualPromptResults?.url && item.manualPromptResults.url.length > 0 && (
                      <a href={item.manualPromptResults?.url} target="_blank" rel="noopener noreferrer">
                      Link
                    </a>
                    )}
                  </div>
                )}
                {editingNoteId === item.noteID && (
                  <div className="note-edit">
                    <div className="input-group">
                      <label className="input-label" htmlFor="email-subject">Title</label>
                      <input
                        type="text"
                        id="email-subject"
                        className="note-edit-input"
                        value={editedEmailSubject}
                        onChange={(e) => setEditedEmailSubject(e.target.value)}
                        placeholder="Email Subject"
                      />
                    </div>
                    <div className="input-group">
                      <label className="input-label" htmlFor="note-body">Body</label>
                      <textarea
                        id="note-body"
                        className="note-edit-textarea"
                        value={editedNote}
                        onChange={(e) => setEditedNote(e.target.value)}
                        placeholder="Note Body"
                      />
                    </div>
                    <div className="input-group">
                      <label className="input-label" htmlFor="note-link">Link</label>
                      <input
                        type="text"
                        id="note-link"
                        className="note-edit-input"
                        value={editedLink}
                        onChange={(e) => setEditedLink(e.target.value)}
                        placeholder="Note Link"
                      />
                    </div>

                  </div>
                )}
                <div className="tabs">
                  {['Note Details', 'My Tags', 'Recommendations'].map(tabName => (
                    <div key={tabName} className="tab">
                      <button className={`tab-label ${selectedTab === `${item.noteID}-${tabName}` ? 'active' : ''}`} onClick={(e) => {
                        e.stopPropagation();
                        handleTabClick(item.noteID, tabName);
                      }}>
                        {tabName}
                        {tabName === 'Note Details' && (
                          <span
                            className={`color-indicator ${item.manualPromptResults?.manualTags?.Categorization?.toLowerCase().replace(' ', '-') || item.manualPromptResults?.manualTags?.Color?.toLowerCase().replace(' ', '-') || item.aiPromptResults?.category?.[0]?.color?.value.toLowerCase().replace(' ', '-')}`}
                          ></span>
                        )}
                      </button>
                    </div>
                  ))}
                </div>
                {['Note Details', 'My Tags', 'Recommendations'].map(tabName => (
                  selectedTab === `${item.noteID}-${tabName}` && (
                    <div key={tabName} className="tab-content">
                      {renderTabContent(item, tabName).map((content, index) => (
                        <div key={index} className="tab-element">{content}</div>
                      ))}
                    </div>
                  )
                ))}
               {editingNoteId === item.noteID && (
                  <div className="button-group">
                    <button className="save-note-button" onClick={() => handleSaveNote(item.noteID)}>Save</button>
                    <button className="cancel-edit-button" onClick={handleCancelEdit}>Cancel</button>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div className="pagination">
            {Array.from({ length: Math.ceil(filteredNotes.length / notesPerPage) }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
              >
                {index + 1}
              </button>
            ))}
          </div>
          
        </div>
       
        <div className="map">
          {isLoaded ? (
              <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={zoom}
              options={{
                mapId: 'YOUR_MAP_ID',
                mapTypeControl: true,
                mapTypeControlOptions: {
                  style: window.google.maps.MapTypeControlStyle.DEFAULT,
                  position: window.google.maps.ControlPosition.TOP_RIGHT,
                },
                fullscreenControl: true,
                streetViewControl: true,
                zoomControl: true,
              }}
              onLoad={(map) => {
                mapRef.current = map;
                createAdvancedMarkers(mapRef.current, markers);
              }}
              onIdle={() => {
                createAdvancedMarkers(mapRef.current, markers);
              }}
              onClick={(e) => handleMapClick(e)}
            >
              <Autocomplete
                onLoad={(autocomplete) => {
                  autocompleteRef.current = autocomplete;
                }}
                onPlaceChanged={() => {
                  const place = autocompleteRef.current.getPlace();
                  if (place && place.geometry) {
                    const location = place.geometry.location;
                    setCenter({ lat: location.lat(), lng: location.lng() });
                    setZoom(12);

                    if (mapRef.current) {
                      mapRef.current.panTo({ lat: location.lat(), lng: location.lng() });
                      mapRef.current.setZoom(12);
                    }
                  }
                }}
              >
                <input
                  type="text"
                  placeholder="Search for a place"
                  className="autocomplete-input"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (autocompleteRef.current) {
                        const place = autocompleteRef.current.getPlace();
                        if (place && place.geometry) {
                          const location = place.geometry.location;
                          setCenter({ lat: location.lat(), lng: location.lng() });
                          setZoom(12);

                          if (mapRef.current) {
                            mapRef.current.panTo({ lat: location.lat(), lng: location.lng() });
                            mapRef.current.setZoom(12);
                          }
                        }
                      }
                    }
                  }}
                />
              </Autocomplete>
              {clickPosition &&  (
                <div
                  className="create-note-overlay"
                  style={{
                    position: 'absolute',
                    left: `${clickPosition.x}px`,
                    top: `${clickPosition.y}px`,
                  }}
                >
                  <button className="create-note-button-map" onClick={() => handleCreateNoteClickMap(clickPosition)}>
                    Create Note
                  </button>
                </div>
              )}
              <div className="map-legend">
                <h3>Categories</h3>
                <ul>
                  {Object.entries(categorization).map(([color, description]) => {
                    // Convert the color name to lowercase and replace spaces with hyphens for matching class names
                    const colorClass = color.toLowerCase().replace(' ', '-');
                    return (
                      <li key={color} className="legend-item">
                        <span className={`legend-icon color-indicator ${colorClass}`}></span>
                        <span>{description}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </GoogleMap>
            
          ) : (
            <div>Loading...</div>
          )}
        </div>

      </div>
      <div className="footer">
        <div className="footer-left">
          <span>© {new Date().getFullYear()} Ideal Scribe. All rights reserved.</span>
        </div>
        <nav className="footer-links">
          <Link to="/terms-of-service">Terms of Service</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/support">Support</Link>
          <div className="social-links">
            <a href="https://medium.com" target="_blank" rel="noopener noreferrer"><FaMedium /></a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MainApp;
