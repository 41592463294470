// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import {
  Authenticator,
  ThemeProvider,
  createTheme,
  useTheme,
} from '@aws-amplify/ui-react';

import Home from './Home';
import StartupGuide from './StartupGuide';
import ProtectedAcctSettings from './ProtectedAccountSettings';
import MainApp from './MainApp';
import CreateNote from './CreateNote';
import TermsOfService from './terms-of-service';
import PrivacyPolicy from './privacy-policy';
import Support from './Support';
import withAuth from './withAuth';
import CustomSignIn from './CustomSignIn';
import Verify from './verify';
import { defaultTheme } from '@aws-amplify/ui-react';
import { WebSocketProvider } from './WebSocketContext';
import AuthenticatedLayout from './AuthenticatedLayout'; // Import the new layout
import ReactGA from 'react-ga4';

Amplify.configure(awsExports);

const ProtectedApp = withAuth(MainApp);
const ProtectedCreateNote = withAuth(CreateNote);
const ProtectedAccountSettings = withAuth(ProtectedAcctSettings);

function App() {
  useEffect(() => {
    ReactGA.initialize('G-8CETR009GH'); // Replace with your Measurement ID
    ReactGA.send('pageview'); // Tracks page views
  }, []);

  return (
    <ThemeProvider>
        <Router basename="/">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/startup-guide" element={<StartupGuide />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/support" element={<Support />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/auth" element={<CustomSignIn />} />

            {/* Protected Routes inside AuthenticatedLayout */}
            <Route element={<AuthenticatedLayout />}>
              {/* Protected Routes */}
              <Route path="/app" element={<ProtectedApp />} />
              <Route path="/account-settings" element={<ProtectedAccountSettings />} />
              <Route path="/create-note" element={<ProtectedCreateNote />} />
            </Route>
          </Routes>
        </Router>
    </ThemeProvider>
  );
}

export default App;
